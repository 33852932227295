<template>
  <div id="pages-layout">
    <div>
      <nuxt />
    </div>
  </div>
</template>
<script>
export default {
  middleware: ["mainten"],
  head() {
    return {
      meta: [
        {
          name: "robots",
          content: this.isProduction ? "index, follow" : "noindex"
        }
      ]
    };
  },
  computed: {
    isProduction() {
      return process.env.ENV === "production";
    }
  }
};
</script>
<style lang="scss"></style>
